cs.ns("app.ui.composite.directEntry")
app.ui.composite.directEntry.view = cs.clazz({
    extend:   app.ui.composite.abstract.modal.view,
    dynamics: {
        markupName: "appDirectEntry"
    },
    protos:   {

        focus () {
            this.vue.$refs.inputToken.focus()
        }
    }
})